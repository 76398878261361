import { TRANSITIONS } from '@/plugins/primeVue/transitions';
export const calendar = {
  root: ({ props }) => ({
    class: [
      'inline-flex max-w-full relative',
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
      },
    ],
  }),
  input: {
    class: [
      'font-sans text-base text-gray-600 bg-white p-1 border border-gray-300 transition-colors duration-200 appearance-none rounded-lg min-w-[250px]',
      'hover:border-blue-500', // Hover
    ],
  },
  panel: ({ props }) => ({
    class: [
      'bg-white',
      'min-w-[350px]',
      {
        'shadow-md border-0 absolute': !props.inline,
        'inline-block overflow-x-auto border border-gray-300 p-2 rounded-lg': props.inline,
      },
    ],
  }),
  header: {
    class: ['flex items-center justify-between', 'p-2 text-gray-700 font-semibold m-0 border-b border-gray-300 rounded-t-lg'],
  },
  previousbutton: {
    class: [
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
    ],
  },
  title: 'leading-8 mx-auto',
  monthTitle: {
    class: ['text-gray-700 transition duration-200 font-semibold p-2', 'mr-2', 'hover:text-blue-500'],
  },
  yearTitle: {
    class: ['text-gray-700 transition duration-200 font-semibold p-2', 'hover:text-blue-500'],
  },
  nextbutton: {
    class: [
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200 ',
    ],
  },
  table: {
    class: ['border-collapse w-full', 'my-2'],
  },
  tableheadercell: 'p-2',
  weekday: 'text-gray-600',
  day: 'p-2',
  daylabel: ({ context }) => ({
    class: [
      'w-10 h-10 rounded-full transition-shadow duration-200 border-transparent border',
      'flex items-center justify-center mx-auto overflow-hidden relative',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      {
        'opacity-60 cursor-default': context.disabled,
        'cursor-pointer': !context.disabled,
      },
      {
        'text-gray-600 bg-transprent hover:bg-gray-200': !context.selected && !context.disabled,
        'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
      },
    ],
  }),
  monthpicker: 'my-2',
  month: ({ context }) => ({
    class: [
      'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      {
        'text-gray-600 bg-transprent hover:bg-gray-200': !context.selected && !context.disabled,
        'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
      },
    ],
  }),
  yearpicker: {
    class: ['my-2'],
  },
  year: ({ context }) => ({
    class: [
      'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      {
        'text-gray-600 bg-transprent hover:bg-gray-200': !context.selected && !context.disabled,
        'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
      },
    ],
  }),
  timepicker: {
    class: ['flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2'],
  },
  separatorcontainer: 'flex items-center flex-col px-2',
  separator: 'text-xl',
  hourpicker: 'flex items-center flex-col px-2',
  minutepicker: 'flex items-center flex-col px-2',
  ampmpicker: 'flex items-center flex-col px-2',
  incrementbutton: {
    class: [
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-8 h-8 text-gray-600 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
    ],
  },
  decrementbutton: {
    class: [
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
    ],
  },
  groupcontainer: 'flex',
  group: {
    class: ['flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0'],
  },
  transition: TRANSITIONS.overlay,
};
