
import { TRANSITIONS } from '@/plugins/primeVue/transitions';

export const password = {
    root: ({ props }) => ({
        class: [
            'inline-flex relative w-full',
            {
                'opacity-60 select-none pointer-events-none cursor-default': props.disabled
            }
        ]
    }),
    panel: 'p-5 bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 shadow-md rounded-md',
    meter: 'mb-2 bg-gray-300 dark:bg-gray-700 h-3',
    meterlabel: ({ instance, props }) => ({
        class: [
            'transition-width duration-1000 ease-in-out h-full',
            {
                'bg-red-500': instance?.meter?.strength == 'weak',
                'bg-orange-500': instance?.meter?.strength == 'medium',
                'bg-green-500': instance?.meter?.strength == 'strong'
            },
            { 'pr-[2.5rem] ': props.toggleMask }
        ]
    }),
    showicon: {
        class: ['absolute top-1/2 -mt-2', 'right-3 text-gray-600 dark:text-white/70']
    },
    hideicon: {
        class: ['absolute top-1/2 -mt-2', 'right-3 text-gray-600 dark:text-white/70']
    },
    transition: TRANSITIONS.overlay
}
  