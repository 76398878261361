import { defineStore } from 'pinia';

interface State {
  accessToken: string | null;
  refreshToken: string | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    accessToken: null,
    refreshToken: null
  }),
  getters: {
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken
  },
  actions: {
    setAccessToken(accessToken: string) {
      this.accessToken = accessToken;
    },
    setAuthTokens(accessToken: string, refreshToken: string) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    destroySession() {
      this.accessToken = null;
      this.refreshToken = null;
    }
  },
  persist: true
});
