export const inputtext = {
  root: ({ props, context }) => ({
    class: [
      'm-0',
      'font-sans text-gray-800 bg-white transition-colors duration-200 appearance-none rounded-md shadow-xl w-full border-gray-200',
      {
        'focus:outline-none focus:outline-offset-0 focus:borde-none': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      {
        'text-lg px-4 py-4': props.size == 'large',
        'text-xs px-2 py-2': props.size == 'small',
        'p-1.5 text-sm': props.size == null,
      },
    ],
  }),
};
