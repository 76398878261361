<template>
  <div v-show="loadingStore.loading" class="flex justify-center items-center relative inset-0 z-[1000] m-auto">
    <section class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 block m-auto bg-[#f3f3f3] dark:bg-mainDark p-10 rounded-2xl shadow-2xl sm:w-96 max-w-sm">
      <div class="flex justify-start items-center">
        <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-blue-600 ml-5"></div>
        <p class="text-center text-blue-600 ml-5 text-xl">Loading...</p>
      </div>
    </section>
    <div class="fixed w-full h-full top-0 bottom-0 left-0 right-0 block z-50"></div>
  </div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '@/stores/loading';

const loadingStore = useLoadingStore();
</script>
