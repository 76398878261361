import { defineStore } from 'pinia';
import { UnwrapRef } from "vue";
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import { useAuthStore } from "@/stores/auth";

interface IState {
  languageCode: string;
  darkMode: boolean;
}

export const useUserStore = defineStore('user', {
  state: (): IState => ({
    languageCode: '',
    darkMode: false
  }),
  getters: {
    getIsLoggedIn: (): UnwrapRef<Boolean> => {
      const authStore = useAuthStore();
      return !!authStore.refreshToken;
    },
    getLanguageCode: (state) => state.languageCode,
    getIsDarkMode: (state) => state.darkMode
  },
  actions: {
    async setLanguage(languageCode: string) {
      this.languageCode = languageCode;
      // Load locale messages
      if (!i18n.global.availableLocales.includes(languageCode)) {
        await loadLocaleMessages(i18n, languageCode);
      }
      // Set i18n language
      setI18nLanguage(i18n, languageCode);
    }
  },
  persist: true
});
