export const tag = {
  root: ({ props }) => ({
    class: [
      'inline-flex items-center justify-center',
      'bg-blue-500 text-white text-xs font-semibold px-1 ',
      {
        'bg-orange-500 ': props.severity == 'warning',
        'bg-gray-300 ': props.severity == 'disabled',
        // For Campaign statuses
        'bg-green-500': props.severity == 'active' || props.severity == 'success',
        'bg-yellow-400': props.severity == 'incompleteRegistration',
        'bg-blue-500 ': props.severity == 'info' || props.severity == 'waitingForActivation',
        'bg-red-500 ': props.severity == 'danger' || props.severity == 'exhausted' || props.severity == 'paused' || props.severity == 'closed',
      },
      {
        'rounded-md': !props.rounded,
        'rounded-full': props.rounded,
      },
    ],
  }),
  value: 'leading-6',
  icon: 'mr-1 text-sm',
};
