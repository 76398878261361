import { TRANSITIONS } from '@/plugins/primeVue/transitions';

export const menu = {
  root: 'bg-primary text-white w-48',
  menu: {
    class: ['m-0 pl-2 rounded-l-lg px-0 list-none', 'outline-none']
  },
  content: ({ context, state }) => ({
    class: [
      'transition-shadow duration-200 rounded-none my-2 first:pt-0 first:mt-0 cursor-pointer',
      '', // Hover
      /* {
        'bg-secondary text-primary': context.focused // Active
      },
      {
        'text-secondary bg-primary ': !context.focused // unActive
      },*/
    ]
  }),
  action: {
    class: [
      'text-gray-700 dark:text-white/80 py-3 px-5 select-none',
      'cursor-pointer flex items-center no-underline overflow-hidden relative'
    ]
  },
  submenuheader: {
    class: [
      'm-0 p-3 text-gray-700 dark:text-white/80 bg-white dark:bg-gray-900 font-bold rounded-tl-none rounded-tr-none'
    ]
  },
  transition: TRANSITIONS.overlay
};
