export const tristatecheckbox = {
  root: {
    class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6']
  },
  checkbox: ({ props }) => ({
    class: [
      'flex items-center justify-center',
      'border-2 w-6 h-6 rounded-lg transition-colors duration-200',
      {
        'border-blue-500 bg-blue-500 text-white':
          props.modelValue || !props.modelValue,
        'border-gray-300 text-gray-600 bg-white':
          props.modelValue == null
      },
      {
        'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]':
          !props.disabled,
        'cursor-default opacity-60': props.disabled
      }
    ]
  })
};
