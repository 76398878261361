import { RouteRecordRaw } from 'vue-router';
import { LayoutEnum } from '@/enums/LayoutEnum';
import { PUBLIC_ROUTES } from '@/enums/PublicRoutesEnum';

const routes: Array<RouteRecordRaw> = [
  // Admin routes
  {
    path: '',
    name: PUBLIC_ROUTES.MAIN_PAGE,
    meta: {
      layout: LayoutEnum.PUBLIC,
      guest: true,
      client: false,
      admin: false,
    },
    component: () => import('@/modules/mainPage/pages/Welcome.vue'),
  },
  {
    path: '/products',
    name: PUBLIC_ROUTES.PRODUCTS,
    meta: {
      layout: LayoutEnum.PUBLIC,
      guest: true,
      client: false,
      admin: false,
    },
    component: () => import('@/modules/products/pages/Products.vue'),
    props: (route) => ({ searchQuery: route.query.search }),
  },
  {
    path: '/product-detail/:itemId/:eshopId',
    name: PUBLIC_ROUTES.PRODUCT_DETAIL,
    meta: {
      layout: LayoutEnum.PUBLIC,
      guest: true,
      client: false,
      admin: false,
    },
    component: () => import('@/modules/products/pages/ProductDetail.vue'),
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: {
      name: PUBLIC_ROUTES.MAIN_PAGE,
    },
  },
];

export default routes;
