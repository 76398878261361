export interface ILanguage {
  code: string;
  text: string;
  flag: string;
}

const LanguagesCodebook: Array<ILanguage> = [
  {
    code: 'cs',
    text: 'Česky',
    flag: '/flags/CZ.png'
  },
  {
    code: 'en',
    text: 'English',
    flag: '/flags/GB.png'
  }
];

export default LanguagesCodebook;
